@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body>iframe{
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --black: #000000;
  --dark: #121314;
  --gray: #919094;
  --middle-gray: #C2C2C2;
  --light-gray: #2D2D35;
  --white: #ffffff;
  --primary: #E2A23B;
  --secondary: #E9DEC3;
  --red: #DD4333;
}

.fade-in {
  animation: fadeInAnimation ease 1.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.bold {
  font-weight: 600 !important;
}

.bolder {
  font-weight: 800 !important;
}

.font-classic {
  font-family: Playfair Display !important;
}

.topbar-reduce {
  background-color: #121314 !important;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
      transform: translateY(30px);
  }
  100% {
      opacity: 1;
      transform: translateY(0px);
   }
}
